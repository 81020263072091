import { useTranslation } from "react-i18next";

const Conductor = ({ renderBody }) => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <h4 className="text-capitalize newstitle">massimo belli</h4>
        <div className="text-justify mt-5">{t("conductor.par1")}</div>
        <div className="row mt-5">
          <div className="col-sm text-center">
            <img
              src="https://res.cloudinary.com/orchestrabusoni/image/upload/v1647612096/foto-di-F.Parenzan-8_halpwx.jpg"
              alt="massimo belli"
              className="rounded_img mb-3"
              width={"80%"}
            />
          </div>
          <div className="col-sm text-justify">
            {renderBody(t("conductor.par2"))}
          </div>
        </div>
        <div className="text-justify">{renderBody(t("conductor.par3"))}</div>
        <div className="text-justify">{renderBody(t("conductor.par4"))}</div>
      </div>
    </section>
  );
};

export default Conductor;
