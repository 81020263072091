import ExtraArtist from "./ExtraArtist";

const Artists = ({ concert, t, i18n }) => {
  return (
    <>
      {concert.artists.length > 0 && (
        <>
          <h4 className="text-capitalize titlenotaligned mt-5">
            {t("concerts.artists")}
          </h4>

          <ExtraArtist concert={concert} t={t} i18n={i18n} />

          {concert.artists.map(({ name, instrument, id }) => (
            <div key={id}>
              {name === "orchestra_busoni" ? (
                <>
                  <div className="text-left">
                    <h5 style={{ color: "#131313", fontWeight: "500" }}>
                      {t(`concerts.${name}`)}
                    </h5>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col col-lg-3">
                    <h5 className="colorok">{name}</h5>
                  </div>
                  <div className="col">
                    <h5 className="colorok">{t(`concerts.${instrument}`)}</h5>
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Artists;
